import { useLocation } from '@gatsbyjs/reach-router'
import React, { useEffect, useState } from 'react'

import DatacError from '../../assets/images/datac-error.svg'
import DatacSuccess from '../../assets/images/datac-success.svg'
import { Layout } from '../../components/SubjectDashboard/Layout'
import { SessionTimeoutModal } from '../../components/auth/SessionTimeoutModal'
import { DatacMessage } from '../../components/common'
import { useScopedIntl } from '../../hooks'
import { AccountType, verifyEmail } from '../../requests'

const SubjectsSignUpConfirmationPage: React.FC = () => {
  const intlEmail = useScopedIntl('subject_dashboard.edit_email')
  const intl = useScopedIntl('')
  const [accountVerified, setAccountVerified] = useState<boolean>(null)
  const location = useLocation()
  const token = location.hash.slice(1)

  useEffect(() => {
    verifyEmail(
      { token },
      {
        onSuccess: () => setAccountVerified(true),
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setAccountVerified(false)
        }
      }
    )
  }, [])

  return (
    <Layout hasBackToDashboard hasChildrenInBoxes isCentered>
      <div>
        <SessionTimeoutModal accountType={AccountType.Subject} />
        {accountVerified ? (
          <DatacSuccess className="simple-content__image" />
        ) : (
          <DatacError className="simple-content__image" />
        )}
        {intlEmail(accountVerified ? 'message_success' : 'message_error')}
      </div>
    </Layout>
  )
}

export default SubjectsSignUpConfirmationPage
